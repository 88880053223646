export default function parseOrderPrices(calculatedPrice) {
	let isOnSale = false;
	let price = 0;
	let listPrice = 0;
	let regulationPrice = 0;
	let discount = false;

	if(calculatedPrice.listPrice && calculatedPrice.listPrice !== (calculatedPrice.unitPrice || calculatedPrice.price)) {
		isOnSale = true;
		listPrice = calculatedPrice.listPrice;
		discount = {percentage: calculatedPrice.listPrice.percentage, amount: calculatedPrice.listPrice.discount};
	}

	price = calculatedPrice.unitPrice || calculatedPrice.price;
	regulationPrice = calculatedPrice?.regulationPrice?.price || calculatedPrice.price;

	return {isOnSale: isOnSale, price: price, listPrice: listPrice, regulationPrice: regulationPrice, discount: discount}
}