const NextI18Next = require('next-i18next').default;
const path = require('path');

const detector = {
    name: 'check-language-header',
    lookup: (req, res, options) => {
        if(!options) {
            let publicConfig = window.__NEXT_DATA__.props.publicConfig;
            return publicConfig ? publicConfig.language.locale : false;
        }

        return req.currentDomainConfig ? req.currentDomainConfig.public.language.locale : 'en';
    }
}

module.exports = {
    fallbackLng: ['en'],
    defaultLanguage: 'sv',
    otherLanguages: ['nb', 'sv', 'en', 'fi', 'nl', 'dk', 'de'],
    localeSubpaths: {},
    defaultNS: 'general',
    localePath: path.resolve('./public/lang/'),
	detection: {
		order: ['check-language-header'],
	},
	customDetectors: [detector],
}