export default function parsePrices(calculatedPrice) {
	let isOnSale = false;
	let price = 0;
	let unitPrice = 0;
	let listPrice = 0;
	let regulationPrice = 0;
	let discount = false;

	if(calculatedPrice.listPrice) {
		isOnSale = true;
		listPrice = calculatedPrice.listPrice.price * calculatedPrice.quantity;
		discount = {percentage: calculatedPrice.listPrice.percentage, amount: calculatedPrice.listPrice.discount};
	}

	price = calculatedPrice?.totalPrice || calculatedPrice.unitPrice;
	unitPrice = calculatedPrice.unitPrice;
	regulationPrice = calculatedPrice?.regulationPrice?.price || price;

	return {isOnSale: isOnSale, price: price, unitPrice:unitPrice, listPrice: listPrice, regulationPrice: regulationPrice, discount: discount}
}