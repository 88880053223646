import { createMuiTheme } from '@material-ui/core/styles';
import createTheme from 'react-storefront/theme/createTheme';

const defaultTheme = createMuiTheme();

// Create a theme instance.
const theme = createTheme({
	palette: {
		primary: {
			main: '#12284C',
			text: '#fff'
		},
		secondary: {
			main: '#24b47e',
			text: '#fff'
		},
		error: {
			main: '#fb5252',
			text: '#fff'
		},
		background: {
			body: '#ffffff',
			element: '#ffffff'
		},
		text: {
			black: "#333",
			grey: "#555"
		},
		newport: {
			blue: '#11284a'
		}
	},
	typography: {
		fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		fontWeight: 300,
		headerFont: 'Optima, serif',
		h1: {
			fontFamily: '"Times New Roman", serif',
			fontSize: 32,
			fontWeight: 400
		},
		h2: {
			fontFamily: '"Times New Roman", serif',
			fontSize: 28,
			fontWeight: 400
		},
		h3: {
			fontFamily: '"Times New Roman", serif',
			fontSize: 24,
			fontWeight: 300
		},
		h4: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		h5: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		h6: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		subtitle1: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		subtitle2: {
			fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
		},
		body1: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		body2: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		button: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		productPrice: {
			fontFamily: '"Futura", serif',
			fontSize: 18,
			fontWeight: 400
		},
		dropdown: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
			fontSize: 14,
			fontWeight: 400,
			color: '#333',
			textTransform: 'none'
		},
		caption: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		overline: {
			fontFamily: '"Futura", "Helvetica", "Arial", sans-serif',
		},
		menu: {
			serif: {
				fontFamily: '"Times New Roman", serif',
				fontSize: '12px',
				letterSpacing: '0.05em'
			},
			sansSerif: {
			}
		},
	},
	cmsContent: {
		color: 'white',
		'& h1': {
			fontFamily: 'Chronicle, serif',
			fontSize: 32,
			fontWeight: 400,
			color: '#212121',
			lineHeight: 1.2,
			margin: 0,
			marginBottom: 10
		},
		'& h2': {
			margin: 0,
			fontFamily: 'Chronicle, serif',
			fontSize: 22,
			fontWeight: 400,
			textAlign: 'center',
			color: '#000',
			lineHeight: 1.2,
		},
		'& h3': {
			margin: 0,
			fontFamily: 'Chronicle, serif',
			fontSize: 18,
			lineHeight: 1.25,
			fontWeight: 400,
			color: '#333'
		},
		'& h4': {
			margin: 0,
			fontFamily: 'Chronicle, serif',
			fontSize: 16,
			fontWeight: 400,
			color: '#333'
		},
		'& a': {
			color: 'inherit'
		},
		'& .font-fenice': {
			fontFamily: 'Chronicle, serif',
    		fontWeight: 400,
		},
		'& .font-didot': {
			fontFamily: 'Chronicle, serif',
    		fontWeight: 600,
		},
		'& .font-lucia': {
			fontFamily: 'Lucia, serif',
    		fontWeight: 400,
			position: 'relative',
			zIndex: 10,
			textShadow: '0px 0px 0.5px black',
			lineHeight: 1.25
		},
		'& .font-red-bright': {
			fontFamily: 'Red Bright, serif',
			fontSize: 80,
    		fontWeight: 400,
		},
		'& .pre-heading': {
			display: 'none',
			color: '#fff',
			fontSize: 13,
			textAlign: 'center',
			fontWeight: 500,
			marginBottom: 5,
			letterSpacing: 2.25,
			textTransform: 'uppercase'
		},
		'& .pre-heading-christmas': {
			display: 'none',
			fontFamily: '"Great Vibes", serif',
			fontSize: '28px',
			letterSpacing: '3.5px',
			color: '#fff',
			textTransform: 'none',
			lineHeight: '18px',
			textAlign: 'center'
		},
		'& .pre-heading--thin': {
			display: 'none',
			marginBottom: 20,
			fontSize: 16,
			letterSpacing: 2.25,
			color: '#fff',
			fontWeight: 300,
			textAlign: 'center'
		},
		'& p': {
			marginTop: 0,
			marginBottom: 5,
			fontSize: '1.2rem'
		},
		'& img': {
			maxWidth: '100%'
		},
		'& ul': {
			paddingLeft: 0,
			listStylePosition: 'inside',
			fontSize: 14
		},
		'& ul li': {
			fontSize: 'inherit',
			color: 'inherit'
		},
		'& h1:first-child, & h2:first-child': {
			marginTop: 0
		},
		'& .show-mob': {
			display: 'none !important'
		},
		'& .hide-mob': {
			display: 'block !important'
		},
		'& .btn-primary': {
			display: 'inline-block',
			padding: '15px 30px 15px 30px',
			fontSize: 14,
			fontWeight: 600,
			color: '#fff',
			borderRadius: 0,
			lineHeight: '30px',
			letterSpacing: '0.5px',
			textDecoration: 'none',
			background: '#42BDCB',
			'&.btn-sm': {
				padding: '12px 24px 12px 24px',
				fontSize: 12,
				fontWeight: 400,
				lineHeight: '22px',
			}
		},
		'& .btn-secondary': {
			display: 'inline-block',
			padding: '12px 30px 12px 30px',
			fontSize: 14,
			fontWeight: 500,
			color: '#fff',
			borderRadius: 0,
			letterSpacing: '0.5px',
			textDecoration: 'none',
			background: '#212121',
			'&.btn-sm': {
				padding: '12px 24px 12px 24px',
				fontSize: 12,
				fontWeight: 400,
				lineHeight: '22px',
			}
		},
		'& .btn-bordered': {
			display: 'inline-block',
			padding: '15px 45px 15px 45px',
			fontSize: 14,
			fontWeight: 600,
			color: '#fff',
			border: '2px solid #fff',
			borderRadius: 0,
			letterSpacing: '0.5px',
			textDecoration: 'none',
			background: 'transparent',
			'&.light-bg': {
				color: '#212121',
				border: '2px solid #212121'
			},
			'&.btn-sm': {
				padding: '12px 24px 12px 24px',
				fontSize: 12,
				fontWeight: 400,
				lineHeight: '20px',
			}
		},
		'& iframe': {
			maxWidth: '100%'
		},
		['@media screen and (max-width: 720px)']: {
			'& .btn-bordered': {
				padding: '15px 30px 15px 30px'
			},
			'& .btn-primary, & .btn-secondary': {
				fontWeight: 400,
				letterSpacing: 0
			},
			'& .show-mob': {
				display: 'block !important'
			},
			'& .hide-mob': {
				display: 'none !important'
			},
			'& .pre-heading': {
				fontSize: 14,
				marginBottom: -5
			},
			'& .pre-heading-christmas': {
				fontSize: '20px'
			},
			'& .pre-heading--thin': {
				letterSpacing: 1,
				marginBottom: -5
			}
		}
	},
	cmsContentMobile: {
		'& h1': {
			fontSize: '28px !important'
		},
		'& .pre-heading': {
			display: 'block'
		},
		'& .text': {
			display: 'none'
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 720,
			md: 1024,
			lg: 1600,
			xl: 1800
		}
	},
	spacing: [0, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
	maxWidth: '1600px',
	logo: {
		url: '/images/newport-logo.png',
		greyUrl: '/images/newport-logo-grey.png',
		alt: 'Newport Collection'
	},
	icons: {
		search: '/images/icons/search.svg',
		cart: '/images/icons/basket.svg'
	},
	boxShadow: {
		0: 'none',
		1: '0 0px 2px rgba(0,0,0,0.02), 0 1px 4px rgba(0,0,0,0.02), 0 2px 6px rgba(0,0,0,0.02), 0 4px 8px rgba(0,0,0,0.02)',
		2: '0 0px 3px rgba(0,0,0,0.03), 2px 2px 6px rgba(0,0,0,0.03), 4px 4px 9px rgba(0,0,0,0.03), 4px 4px 16px rgba(0,0,0,0.03)',
		3: '0 0px 4px rgba(0,0,0,0.04), 3px 3px 8px rgba(0,0,0,0.04), 5px 6px 12px rgba(0,0,0,0.04), 5px 5px 24px rgba(0,0,0,0.04)',
		4: '0 0px 5px rgba(0,0,0,0.05), 4px 4px 10px rgba(0,0,0,0.05), 6px 8px 15px rgba(0,0,0,0.05), 6px 6px 32px rgba(0,0,0,0.05)',
		5: '0 0px 6px rgba(0,0,0,0.06), 5px 5px 12px rgba(0,0,0,0.06), 7px 10px 18px rgba(0,0,0,0.06), 7px 7px 48px rgba(0,0,0,0.06)',
		6: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
		7: '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
		8: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
		9: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
		10: '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
		11: '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
		12: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
		13: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
		14: '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
		15: '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
		16: '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
		17: '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
		18: '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
		19: '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
		20: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
		dropdown: '0 1px 3px rgb(50 50 93 / 5%)',
		dropdownActive: '3px 3px 10px rgb(50 50 93 / 10%)'
	},
	borderShadow: '0 1px 0 rgb(0 0 0 / 15%), 0 0 1px 0 rgb(0 0 0 / 15%)',
	menu: {
		background: '#fff'
	},
	drawerWidth: '90vw',
	button: {
		primary: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '12px 24px',
			backgroundColor: 'transparent',
			border: 'none'
		},
		purchase: {
			padding: '20px 8px',
			flexGrow: 1,
			color: 'white',
			fontSize: 15,
			fontWeight: 600,
			lineHeight: '16px',
			textTransform: 'uppercase',
			letterSpacing: 2,
			backgroundColor: "#041e3a",
			borderRadius: 0
		},
		purchaseHover: {
			backgroundColor: "#01011d !important"
		}
	},
	overrides: {
		gutters: {
			[defaultTheme.breakpoints.up('sm')]: {
				paddingLeft: '20px',
				paddingRight: '20px',
			},
		},
	}
});

export default theme;

/* Default theme config
{
  breakpoints: {
    keys: [ 'xs', 'sm', 'md', 'lg', 'xl' ],
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
    up: [Function: up],
    down: [Function: down],
    between: [Function: between],
    only: [Function: only],
    width: [Function: width]
  },
  direction: 'ltr',
  mixins: {
    gutters: [Function: gutters],
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': [Object],
      '@media (min-width:600px)': [Object]
    }
  },
  overrides: {},
  palette: {
    common: { black: '#000', white: '#fff' },
    type: 'light',
    primary: {
      light: '#7986cb',
      main: '#3f51b5',
      dark: '#303f9f',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ff4081',
      main: '#f50057',
      dark: '#c51162',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff'
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)'
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    contrastThreshold: 3,
    getContrastText: [Function: getContrastText],
    augmentColor: [Function: augmentColor],
    tonalOffset: 0.2,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: { paper: '#fff', default: '#fafafa' },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  },
  props: {},
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)'
  ],
  typography: {
    htmlFontSize: 16,
    pxToRem: [Function (anonymous)],
    round: [Function: round],
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em'
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em'
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em'
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em'
    },
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em'
    },
    h6: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em'
    },
    subtitle1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em'
    },
    subtitle2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em'
    },
    body1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em'
    },
    body2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em'
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase'
    },
    caption: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em'
    },
    overline: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase'
    }
  },
  spacing: [Function: spacing] { mui: true },
  shape: { borderRadius: 4 },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    },
    create: [Function: create],
    getAutoHeightDuration: [Function: getAutoHeightDuration]
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 999,
    snackbar: 1400,
    tooltip: 1500,
    amp: { modal: 2147483646 }
  },
  headerHeight: 64,
  loadMaskOffsetTop: 124,
  drawerWidth: 330,
  colors: {
    primary: { main: '#556cd6' },
    secondary: { main: '#19857b' },
    error: { main: '#ff2200' },
    background: { default: '#fff' }
  },
  maxWidth: '1440px',
  logo: { url: '/images/newport-logo.png' },
  icons: {
    searcg: '/images/icons/search.svg',
    cart: '/images/icons/basket.svg'
  },
  [Symbol(mui.nested)]: false
}
 */
