import Cookie from 'js-cookie';
module.exports = {
    getToken: function () {
        return Cookie.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN) ? atob(Cookie.get(process.env.NEXT_PUBLIC_MYNEWPORT_SESSION_TOKEN)) : false
    },
    getHeaders: function () {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + this.getToken()
        }
    },
    getWishlists: async function (myNewport) {
        let response = await fetch(myNewport + '/api/wishlist', {
            method: 'GET',
            headers: this.getHeaders(),
        });

        try {
            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'unknown');
        }
    },
    getWeddingList: async function (myNewport) {
        let response = await fetch(myNewport + '/api/wishlist/wedding-list', {
            method: 'GET',
            headers: this.getHeaders(),
        });

        try {
            let responseJson = await response.json();

            if(response.status != 200 || !responseJson.data) {
                return { status: 'error', data: {} };
            }

            return { status: 'success', data: responseJson.data };
        } catch (e) {
            throw new Error(e || 'unknown');
        }
    },
    getWishlistProducts: async function(myNewport, wishlistId) {
        let response = await fetch(myNewport + '/api/wishlist/' + wishlistId, {
            method: 'GET',
            headers: this.getHeaders(),
        });

        try {
            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'unknown');
        }
    },
    getWishlistProductIds: async function(myNewport) {
        let response = await fetch(myNewport + '/api/wishlist/product-ids', {
            method: 'GET',
            headers: this.getHeaders(),
        });

        try {
            let responseJson = await response.json();

            if(response.status !== 200) {
                return [];
            }

            return responseJson?.ids || [];
        } catch (e) {
            throw new Error(e || 'unknown');
        }
    },
    addWishlistProduct: async function(myNewport, wishlistId, productId) {
        let response = await fetch(myNewport + '/api/wishlist/' + wishlistId + '/item', {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify({
                product_id: productId,
                qty: 1
            })
        });

        try {
            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'unknown');
        }
    },
    removeWishlistProduct: async function(myNewport, wishlistId, productId) {
        let response = await fetch(myNewport + '/api/wishlist/' + wishlistId + '/item/' + productId, {
            method: 'DELETE',
            headers: this.getHeaders()
        });

        try {
            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'unknown');
        }
    },
    createWishlist: async function(myNewport, { name, isWedding, text, location, date }) {
        let response = await fetch(myNewport + '/api/wishlist', {
            method: 'POST',
            headers: this.getHeaders(),
            body: JSON.stringify({
                name,
                type: isWedding ? 'wedding' : 'standard',
                text,
                location,
                date
            })
        });

        try {
            let responseJson = await response.json();

            if(responseJson.status !== 'success') {
                return { status: 'error', data: [] };
            }

            return responseJson;
        } catch (e) {
            throw new Error(e || 'unknown');
        }
    }

}